
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import Entities from "@/components/base/common/Entities.vue";
import CookieService from "@/core/services/CookieService";
import { COOKIE_KEY, OPERATOR } from "@/core/config/Constant";
import { ISearchField } from "@/core/entity/ISearchField";
import { SearchEntityEvent } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import ClaimViewCard from "@/views/claim/ClaimViewCard.vue";
import RecentCards from "@/components/base/common/RecentCards.vue";

export default defineComponent({
  name: "Recent",
  components: {RecentCards, ClaimViewCard,  Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Recent Claims',
        [
          {link: false, router: '', text: 'Recent Claims'}
        ]
      )
    })
    const ids = ref("")
    const recentList = CookieService.get(COOKIE_KEY.RECENT_CLAIMS);
    if (recentList) {
      ids.value = JSON.parse(recentList).join(",")
    }
    const filter: ISearchField[] = [
      {column: 'claimId', type: 'HIDDEN', operator: OPERATOR.IN, value: ids.value ? ids.value : '0', name: ''},
    ]
    const claimSearchPage = computed(() => store.state.ClaimModule.claimSearchPage);
    return {
      ...SearchEntityEvent(Actions.CLAIM_SEARCH, filter, ['all'], [], 'claimView'),
      claimSearchPage,
    }
  }
})
